import React, { Component } from 'react';
import './LandingPage.css';
import image1 from './Frontpageiamge1.png';
import image2 from './Frontpageimage2.png';
import image3 from './Frontpageimage3.png';

import logoimage from './SBISSLogo-removebg-preview.png'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0
        };
        this.sliderInterval = null;
    }

    componentDidMount() {
        this.startSlider();
    }

    componentWillUnmount() {
        this.stopSlider();
    }

    startSlider = () => {
        this.sliderInterval = setInterval(() => {
            this.nextSlide();
        }, 3000); // Change slide every 3 seconds
    }

    stopSlider = () => {
        if (this.sliderInterval) {
            clearInterval(this.sliderInterval);
        }
    }

    nextSlide = () => {
        this.setState(prevState => ({
            currentIndex: (prevState.currentIndex + 1) % 3 // Adjust based on number of slides
        }));
    }

    render() {
        const { currentIndex } = this.state;
        const images = [image1, image2, image3];

        return (
            <div className="landing-container">
                <div className="sliderhome">
                    <div className="slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {images.map((src, index) => (
                            <div className="slide" key={index}>
                                <img src={src} alt={`Slide ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                

                
                    <h1 className="landing-title">Welcome</h1>
                    <h1 className="landing-title">Small Business Inventory System</h1>
                    <p className="landing-description">
                        An efficient solution for small businesses to manage inventory, monitor low supplies, and track cost changes over time.
                    </p>

                    <div className="landing-steps">
                        <h2 className="steps-title"><a href="/createaccount">Getting Started</a></h2>
                        <ul className="steps-list">
                            <li><a href="/createaccount">Create an account</a> to get started.</li>
                            <li><a href="/login">Log in</a> using your new account credentials.</li>
                            <li>Explore and manage your inventory with ease.</li>
                        </ul>
                    </div>
                </div>
                <footer class="footer">
                    <div class="footer-content">
                        <div class="footer-logo">
                            <img src={logoimage} alt="Company Logo"/>
                        </div>
                        <div class="footer-links">
                            <ul>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/services">Services</a></li>
                                <li><a href="/contact">Contact</a></li>
                                <li><a href="/privacypolicy">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div class="footer-social">
                            {/*<a href="https://facebook.com" target="_blank">Facebook</a>*/}
                            {/*<a href="https://twitter.com" target="_blank">Twitter</a>*/}
                            {/*<a href="https://linkedin.com" target="_blank">LinkedIn</a>*/}
                        </div>
                    </div>
                    <div class="footer-bottom">
                        <p>&copy; 2025 SBISS. All rights reserved.</p>
                    </div>
                </footer>
            </div>
           
        );
    }
}