import React, { Component } from 'react';
import './ContactPage.css';  // Ensure the styles for AboutPage

export class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: '',
            isAuthenticated: false, // Manage redirection
            token: '',
            successMessage: '',
            error: ''
        };
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.setState({ [name]: value }, () => {
            // Validate email whenever it changes
            if (name === 'email') {
                this.validateEmail(value);
            }

        });
    };
    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  // Simple email validation regex
        const emailValid = emailRegex.test(email);
        this.setState({ emailValid });
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, message } = this.state;

        // Validate the fields
        
        try {
            // Step 1: Send the email to the backend to generate and send reset code
            const emailResponse = await fetch('/account/sendContactEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name, message }),
            });

            if (!emailResponse.ok) {
                const errorText = await emailResponse.text();
                this.setState({ error: errorText || 'Failed to send reset code.' });
                return;
            }
            this.setState({
                successMessage: 'Your message has been sent successfully. We will get back to you as soon as possible.',
                error: '', 
            })
            console.log('Email Sent'); // Debugging

            // Step 3: Store the reset code in the state
           

        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    }

    render() {
        const { name, email, message, successMessage, error } = this.state;
        return (
            <div className="contact-container">
                <h1>Contact Us</h1>
                <p>
                    We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to send us a message.
                    Please fill out the form below, and we’ll get back to you as soon as possible.
                </p>

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Your Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={this.handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Your Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Your Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={message}
                            onChange={this.handleChange}
                            required
                        />
                    </div>

                    <button type="submit" className="submit-button">Send Message</button>
                </form>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {error && <p className="error-message">{error}</p>}
                
            </div>
        );
    }
}