import React, { Component } from 'react';
import './AboutPage.css';  // Ensure the styles for AboutPage

export class AboutPage extends Component {
    render() {
        return (
            <div className="about-container">
                <h1>About Us</h1>
                <p>
                    At SBISS, we’re dedicated to helping small businesses, especially restaurants, manage their inventory more efficiently.
                    Are team, are mission is to provide affordable and easy-to-use software that helps you monitor stock levels, track costs, and make smarter purchasing decisions.
                    By streamlining your inventory process, I aim to help you save time and reduce errors, so you can focus more on growing your business and serving your customers.
                </p>
                <h2>Why SBISS?</h2>
                <p>
                    SBISS stands for Small Business Inventory System. It’s designed to provide small businesses with a simple, yet powerful, way to track their inventory.
                    We understand that managing inventory can be overwhelming, so we’ve built an intuitive, user-friendly platform to help you stay on top of things.
                    Our goal is to provide reliable tools that support your business’s growth.
                </p>
            </div>
        );
    }
}