import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import './Dashboard.css';

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: true,
            navigateToFoodWaste: false,
            navigateToAnalysis: false,
            navigateToInventory: false,
            BusinessPlan: '',
            showItems: false, // Track whether to show items
            items: [],
            userEmail: '',
            // Initialize items as an empty array
        };
    }

    async componentDidMount() {
        const token = localStorage.getItem('token');
        if (!token) {
            this.setState({ isAuthenticated: false });
            return;
        }

        try {
            const userResponse = await fetch('/user/userdetails', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (userResponse.ok) {
                const user = await userResponse.json();
                this.setState({
                    userEmail: user.email,
                    BusinessPlan: user.businessPlan,
                    userID: user.userid,
                    isAuthenticated: true
                });

                // Fetch additional settings based on user ID
                this.fetchSettingsByUserId(user.userid);
            } else {
                const errorText = await userResponse.text();
                console.error('Failed to fetch user details:', userResponse.status, errorText);
                this.setState({
                    isAuthenticated: false,
                    errorMessage: 'Failed to load user details, please try again later.'
                });
            }
        } catch (error) {
            console.error('Error during fetch:', error);
            this.setState({
                isAuthenticated: false,
                errorMessage: 'An unexpected error occurred, please try again later.'
            });
        }
    }

    fetchSettingsByUserId = async (userid) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/inventory/setting?userId=${userid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const settings = await response.json();
                this.setState({
                    Invnameone: settings.invnameone,
                    Invnametwo: settings.invnametwo,
                    Invnamethree: settings.invnamethree,
                    Invnamefour: settings.invnamefour,
                    Invnamefive: settings.invnamefive
                }, () => this.fetchItems(token, 'first'));
            } else {
                const errorText = await response.text();
                console.error('Failed to fetch settings:', errorText);
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    fetchItems = async (token, type) => {
        const currentDate = new Date();
        // Calculate "yesterday"
        const firstDay = new Date(currentDate);
        firstDay.setDate(currentDate.getDate() - 1);  // Set the date to yesterday
        /*firstDay.setMonth(currentDate.getMonth() - 2);*/
        // Calculate "today"
        const lastDay = new Date(currentDate);  // Just use the current date as "today"
        const startDateKey = firstDay.toISOString().split('T')[0];
        const endDateKey = lastDay.toISOString().split('T')[0];

        let endpoint;
        switch (type) {
            case 'first':
                endpoint = '/inventory/items';
                break;
            case 'second':
                endpoint = '/inventory/items2';
                break;
            case 'third':
                endpoint = '/inventory/items3';
                break;
            case 'fourth':
                endpoint = '/inventory/items4';
                break;
            case 'fifth':
                endpoint = '/inventory/items5';
                break;
            default:
                console.error(`Unknown type: ${type}`);
                return;
        }

        const startDateValue = startDateKey;
        const endDateValue = endDateKey;

        if (!startDateValue || !endDateValue) {
            console.error(`Start Date and End Date must be provided for ${type}.`);
            return;
        }

        try {
            const query = new URLSearchParams({
                startDate: startDateValue,
                endDate: endDateValue,
            }).toString();

            const response = await fetch(`${endpoint}?${query}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const items = await response.json();
                const sortedAndFilteredItems = this.filterAndSortItems(items);
                this.setState({
                    items: sortedAndFilteredItems,
                });
            } else {
                const errorText = await response.text();
                this.setState({ error: errorText || `Failed to fetch items for ${type}` });
            }
        } catch (error) {
            console.error(`Error fetching items for ${type}:`, error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    // Function to filter and sort items based on critical level
    filterAndSortItems = (items) => {
        return items
            .filter(item => item.cases <= item.critical) // Filter to show items with cases <= critical
            .sort((a, b) => a.cases - b.cases); // Sort items by the number of cases in ascending order
    };

    handleLogout = async () => {
        try {
            await fetch('/user/checkAllAccountsBusinessPlan', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            });

            localStorage.removeItem('token');
            this.setState({ isAuthenticated: false, userEmail: '', userID: '' });
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };
    handleSubUpdate = async (token) => {
        const { userEmail } = this.state;
        var BusinessPlan = "premium"
        try {
            const subscriptionResponse = await fetch('/user/subscriptioncreate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userEmail, BusinessPlan })
            });

            if (!subscriptionResponse.ok) {
                const errorText = await subscriptionResponse.text();
                this.setState({ error: errorText || 'Failed to create subscription' });
                return;
            }

            const subscriptionResult = await subscriptionResponse.json();

            if (subscriptionResult.plan != null) {
                this.setState({ isSubscribed: true });
            } else {
                this.setState({ error: 'No plan set received from subscription creation' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred: ' + error.message });
        }
    }
    handleSubCancel = async (token) => {
        const { userEmail } = this.state;
        const BusinessPlan = "default";

        // Show confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to cancel your account?");

        if (!isConfirmed) {
            return; // If the user doesn't confirm, stop the process
        }

        try {
            const subscriptionResponse = await fetch('/user/subscriptioncreate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userEmail, BusinessPlan })
            });

            if (!subscriptionResponse.ok) {
                const errorText = await subscriptionResponse.text();
                this.setState({ error: errorText || 'Failed to cancel subscription' });
                return;
            }

            const subscriptionResult = await subscriptionResponse.json();

            if (subscriptionResult.plan != null) {
                this.setState({ isSubscribed: true });
            } else {
                this.setState({ error: 'No plan set received from subscription cancellation' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred: ' + error.message });
        }
    }

    handleFoodwaste = () => {
        this.setState({ navigateToFoodWaste: true });
    };

    handleAnalysis = () => {
        this.setState({ navigateToAnalysis: true });
    };

    handleInventory = () => {
        this.setState({ navigateToInventory: true });
    };
    handleShowItems = () => {
        this.setState(prevState => ({
            showItems: !prevState.showItems
        }));
    };
    render() {
        const { BusinessPlan, isAuthenticated, userEmail, navigateToFoodWaste, navigateToAnalysis, navigateToInventory, items, showItems } = this.state;
        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }
        if (navigateToInventory) {
            return <Navigate to="/inventorysystem" />;
        }
        if (navigateToFoodWaste) {
            return <Navigate to="/foodwaste" />;
        }
        if (navigateToAnalysis) {
            return <Navigate to="/analysis" />;
        }

        

        return (
            <div className="DashBoard-system">
                <h1>Dashboard</h1>
                <p>Welcome, {userEmail}</p>
                <button onClick={this.handleLogout}>Logout</button>
                <div className="inv-button-container">
                    <button onClick={this.handleInventory} className="inv-foodwaste-button">
                        Inventory System
                    </button>
                    {BusinessPlan === 'basic' && (
                        <button onClick={this.handleSubUpdate} className="inv-foodwaste-button">
                            Upgrade Premium
                        </button>
                    )}
                    <button onClick={this.handleSubCancel} className="inv-foodwaste-button">
                        Cancel Account
                    </button>
                    {BusinessPlan === 'premium' && (
                        <button onClick={this.handleFoodwaste} className="inv-foodwaste-button">
                            Food Waste Tracker
                        </button>
                    )}
                    {BusinessPlan === 'premium' && (
                        <button onClick={this.handleAnalysis} className="inv-analysis-button">
                            Analysis
                        </button>
                    )}
                    <button onClick={this.handleShowItems} className="show-items-button">
                        {showItems
                            ? "Hide Critical Alert"
                            : (
                                <>
                                    Show Critical Alert
                                    {items.length > 0 && (
                                        <span className="alert-count">{items.length}</span>
                                    )}
                                </>
                            )
                        }
                    </button>

                </div>

                {/* Show items table if 'showItems' is true */}
                {showItems && items && items.length > 0 && (
                    <div className="items-table">
                        <table>
                            <thead>
                                <tr>
                                   
                                    <th>Name</th>
                                    <th>Cases </th>
                                    <th>Crit</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        
                                        <td>{item.productName}</td>
                                        <td>{item.cases}</td>
                                        <td>{item.critical}</td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}

